import React from "react";
import { Script } from "gatsby";

const AnalyticsByConsent = ({
  gtagId,
  clarityId,
}: {
  gtagId: string;
  clarityId: string;
}) => {
  return (
    <>
      <Script strategy="idle">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityId}");
        `}
      </Script>
      <Script strategy="idle">
        {`
          setTimeout(function() {
            if (!!window?.HubSpotConversations?.widget 
              && !window?.HubSpotConversations?.widget?.status()?.loaded) {
              console.log("widget loading");
              window.HubSpotConversations.widget.load();
            }
          }, 50000);
        `}
      </Script>
      {/* Google tag (gtag.js) */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
        strategy="idle"
      />
      <Script strategy="idle">
        {`
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag() {
            window.dataLayer.push(arguments);
          }
          window.gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `}
      </Script>
      <Script strategy="idle">
        {`
          window.dataLayer = window.dataLayer || [];
          window.gtag = window.gtag || function gtag() {
            window.dataLayer.push(arguments);
          }
          window.gtag('js', new Date());

          window.gtag('config', '${gtagId}', {
            "anonymize_ip": false
          });
        `}
      </Script>

      {/* Consent handling */}
      <Script strategy="idle">
        {`
          var _hsp = (window._hsp = window._hsp || []);
          _hsp.push(["addPrivacyConsentListener", function (consent) {
            if (consent?.categories?.analytics) {
              window.clarity('consent');

              window.gtag('consent', 'update', {
                'analytics_storage': 'granted'
              });

            } else {
              window.clarity('consent', false); 

              window.gtag('consent', 'update', {
                'analytics_storage': 'denied'
              });
              
            }

            if (consent?.categories?.advertisement) {
              window.gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted'
              });
            } else {
              window.gtag('consent', 'update', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied'
              });
            }
          }]);
        `}
      </Script>
    </>
  );
};

export default AnalyticsByConsent;
